// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// FBCE components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useCallback, useEffect, useState } from "react";
import siteData from "services/json-data-api";

function WhoWeAreBody() {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    const json = await siteData.whoWeAre();

    // console.log(json);

    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (data !== undefined) {
    return (
      <MKBox component="section" py={6} mr={2} mt={4}>
        <Container>
          <Grid container spacing={3} item xs={12} lg={8} mx="auto">
            <MKTypography variant="h3" mb={3}>
              {data?.title}
            </MKTypography>
            {data.paragraphs?.map((paragraph) => (
              <MKTypography key={paragraph} variant="body2">
                {paragraph}
                <br />
                <br />
              </MKTypography>
            ))}
          </Grid>
        </Container>
      </MKBox>
    );
  }
}

export default WhoWeAreBody;
