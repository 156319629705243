// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// typed-js
import * as Typed from "typed.js";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/fbce-sanctuary-original.jpg";
import { useEffect, useRef } from "react";
// import ReactPlayer from "react-player";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/footers/DefaultFooter";
import Livestream from "pages/Home/sections/LiveStream";
import Gospel from "pages/Home/sections/Gospel";
import WorshipSection from "pages/Home/sections/Worship";
import useBlogPosts from "hooks/blog-hook";
import BlogPosts from "pages/Blog/sections/Posts";
import { Helmet } from "react-helmet";
// import SmallGroupSignupSection from "./sections/SmallGroupsSignUp";
import AbortionDoc from "./sections/AbortionDoc";
import BibleStudiesSection from "./sections/BibleStudies";
import Times from "./sections/Times";

function Home() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);
  const posts = useBlogPosts();

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["Sola Gratia", "Sola Fide", "Solus Christus", "Sola Scriptura", "Soli Deo Gloria"],
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 300,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <>
      <Helmet>
        <title>FBC-Eclectic</title>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="First Baptist Church Eclectic" />
        <meta property="og:description" content="" />
        <meta
          property="og:image"
          content="https://psides83.github.io/fbce-website-resources/images/fbce-sanctuary-original.jpg"
        />
        <meta name="description" content="" />
        <meta name="keywords" content="church, god, christian, christ, christianity, salvation" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="First Baptist Church Eclectic" />
        <meta
          name="twitter:image"
          content="https://psides83.github.io/fbce-website-resources/images/fbce-sanctuary-original.jpg"
        />
      </Helmet>
      <DefaultNavbar routes={routes} />
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.9),
              rgba(gradients.dark.state, 0.7)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 75%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <span ref={typedJSRef} />
            </MKTypography>
            <MKTypography variant="body1" color="white" textAlign="center" px={6} mt={1}>
              First Baptist Church of Eclectic exists to glorify God by making disciples of Jesus
              Christ at home and abroad.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          py: 2,
          // mx: { xs: 2, lg: 3 },
          mt: -14,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* eslint-disable jsx-a11y/media-has-caption */}
        <Livestream />
        <Times />
        <Gospel />
        <BibleStudiesSection />
        {/* <SmallGroupSignupSection /> */}
        <BlogPosts posts={posts.slice(0, 3)} title="Recent Blog Posts" />
        <WorshipSection />
        <AbortionDoc />
        {/* <video style={{ width: "100%" }} muted autoPlay loop>
          <source src={video} type="video/mp4" />
        </video> */}
        {/* <ReactPlayer url={video} playing loop muted width="100%" height="100%" /> */}
        {/* <AuthPages style={{ width: "100%" }} /> */}
        {/* <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: "external",
                  route:
                    "https://www.creative-tim.com/learning-lab/material-ui-marketplace/overview/otis-kit/",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Plugins"
                description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
                action={{
                  type: "external",
                  route:
                    "https://www.creative-tim.com/learning-lab/material-ui-marketplace/datepicker/otis-kit/",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Components"
                description="Otis Kit PRO is giving you a lot of pre-made components, that will help you to build UI's faster."
                action={{
                  type: "external",
                  route:
                    "https://www.creative-tim.com/learning-lab/material-ui-marketplace/alerts/otis-kit/",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container> */}
        {/* <Testimonials /> */}
        {/* <MKBox pt={18} pb={6}>
           <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton color="twitter" sx={{ mr: 1 }}>
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton color="facebook" sx={{ mr: 1 }}>
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton color="pinterest">
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container> 
        </MKBox> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
