/* eslint-disable */
// @mui material components
import { Container, Grid, Stack } from "@mui/material";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import useTimes from "hooks/times-hook";
// eslint-disable-next-line no-unused-vars
import { useCallback, useEffect, useState } from "react";
// import siteData from "services/json-data-api";

function Times() {
  const times = useTimes();

  return (
    <MKBox component="section" py={6} mr={3} mt={4}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <Stack>
            {times?.map((time) => {
              return (
                <>
                  <MKTypography variant="h4" mb={-1} my={2} key={time.day}>
                    {time.day}:
                  </MKTypography>
                  {time.times.map((service) => (
                    <MKTypography variant="body1" key={time.day}>
                      {service.title} - {service.time}
                    </MKTypography>
                  ))}
                </>
              );
            })}
          </Stack>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Times;
