import React from "react";

import MKBox from "components/MKBox";
// import RotatingCard from "components/cards/RotatingCard";
// import RotatingCardFront from "components/cards/RotatingCard/RotatingCardFront";
// import RotatingCardBack from "components/cards/RotatingCard/RotatingCardBack";
import { GNC_IMAGE } from "data/image-urls";
import { GNC_URL } from "data/urls";
import MinistryCard from "pages/Ministries/components/MinistryCard";

export default function GoodNewsClub() {
  return (
    <MKBox component="section" mt={4} py={4} style={{ display: "flex", justifyContent: "center" }}>
      {/* <RotatingCard>
        <RotatingCardFront image={GNC_IMAGE} icon="touch_app" logo={GNC_LOGO} color="dark" />
        <RotatingCardBack
          image={GNC_IMAGE}
          color="dark"
          description="We partner with Child Evangelism Fellowship of Central Alabama to bring Good News Clubs to Eclectic Elementary students. We also seek to conduct Bible clubs throughout our community during the summer months. These clubs enable us to share with children and families the good news of who God is and what he has done for us through Jesus Christ."
          action={{
            type: "external",
            route: GNC_URL,
            label: "Learn More",
          }}
        />
      </RotatingCard> */}
      <MinistryCard
        image={GNC_IMAGE}
        color="dark"
        title="Good News Club"
        description="We partner with Child Evangelism Fellowship of Central Alabama to bring Good News Clubs to Eclectic Elementary students. We also seek to conduct Bible clubs throughout our community during the summer months. These clubs enable us to share with children and families the good news of who God is and what he has done for us through Jesus Christ."
        action={{
          type: "external",
          route: GNC_URL,
          label: "Learn More",
        }}
      />
    </MKBox>
  );
}
