// @mui material components
import Grid from "@mui/material/Grid";
import BodyWithReferences from "components/BodyWithReferences";

// Otis Kit PRO components
import { useCallback, useEffect, useState } from "react";
import siteData from "services/json-data-api";

function Gospel() {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    const json = await siteData.gospel();

    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Grid container spacing={3} item xs={10} lg={10} mx="auto">
      <BodyWithReferences data={data} />
    </Grid>
  );
}

export default Gospel;
