// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import BodyWithReferences from "components/BodyWithReferences";

// Otis Kit PRO components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import BibleModal from "components/Modal/BibleModal";
import { useCallback, useEffect, useState } from "react";
import siteData from "services/json-data-api";

function MembershipBody() {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    const json = await siteData.membership();

    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <MKBox component="section" py={6} mr={3}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <BodyWithReferences data={data} />
        </Grid>
      </Container>
    </MKBox>
  );
}

export default MembershipBody;
