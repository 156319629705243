// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_DB_API_KEY,
  authDomain: "fbc-eclectic.firebaseapp.com",
  projectId: "fbc-eclectic",
  storageBucket: "fbc-eclectic.appspot.com",
  messagingSenderId: process.env.REACT_APP_DB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DB_APP_ID,
  measurementId: process.env.REACT_APP_DB_MEASUREMENT_ID,
};

// Initialize Firebase
const dbApp = initializeApp(firebaseConfig);

// Authentication
// const analytics = getAnalytics(dbApp);

// Firestore
const db = getFirestore(dbApp);

export { dbApp, db };
