// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

function MinistryCard({ color, image, logo, title, description, action }) {
  return (
    <MKBox
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      borderRadius="lg"
      coloredShadow={color}
      minWidth="260px"
      maxWidth="400px"
      height="500px"
      position="relative"
      zIndex={2}
      sx={{
        mx: "0.5rem",
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
          )}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "30% 50%",
        backfaceVisibility: "hidden",
      }}
    >
      <MKBox py={12} px={3} textAlign="center" lineHeight={1}>
        {logo && (
          <MKBox
            component="img"
            maxWidth="80%"
            maxHeight="95%"
            minWidth="200px"
            alt="logo"
            src={logo}
          />
        )}
        <MKTypography variant="h3" color={color === "light" ? "dark" : "white"}>
          {title}
        </MKTypography>
        <MKTypography
          variant="body2"
          color={color === "light" ? "text" : "white"}
          opacity={0.8}
          mb={3}
        >
          {description}
        </MKTypography>
        {action.type === "internal" ? (
          <MKButton
            component={Link}
            to={action.route}
            variant={color === "light" ? "gradient" : "contained"}
            color={color === "light" ? "dark" : "white"}
            size="small"
          >
            {action.label}
          </MKButton>
        ) : (
          <MKButton
            component="a"
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant={color === "light" ? "gradient" : "contained"}
            color={color === "light" ? "dark" : "white"}
            size="small"
          >
            {action.label}
          </MKButton>
        )}
      </MKBox>
    </MKBox>
  );
}

// Setting default values for the props of MinistryCard
MinistryCard.defaultProps = {
  color: "dark",
  logo: "",
  title: "",
  description: "",
  action: null,
};

// Typechecking props for the MinistryCard
MinistryCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  logo: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.node,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

export default MinistryCard;
