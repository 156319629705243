/* eslint-disable */
import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKTypography from "components/MKTypography";

function FilterTabs({ tabOptions, activeTab, setActiveTab, setPageToken, setPage }) {
  const handleTabType = (event, newValue) => {
    setPage(0)
    setPageToken(undefined)
    setActiveTab(newValue)
};

  return (
    <Container>
      <Grid container item justifyContent="center" xs={12} lg={4} mx="auto">
        <MKTypography variant="h5" my={2}>
          Select Category
        </MKTypography>
        <AppBar position="static" sx={{ zIndex: 1 }}>
          <Tabs value={activeTab} onChange={handleTabType} indicatorColor="primary">
            {tabOptions.map((tab) => (
              <Tab key={tab} label={tab} value={tab} sx={{ fontSize: ".9rem" }} />
            ))}
          </Tabs>
        </AppBar>
      </Grid>
    </Container>
  );
}

export default FilterTabs;
