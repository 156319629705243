// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";

import { useCallback, useEffect, useState } from "react";
import siteData from "services/json-data-api";
import StaffCard from "pages/Staff/components/StaffCard";

function StaffSection() {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    const json = await siteData.staff();

    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      // mx={{ xs: 2, lg: 3 }}
      mt={-14}
      borderRadius="xl"
      sx={{
        boxShadow: ({ boxShadows: { xxl } }) => xxl,
      }}
    >
      <Container>
        <Grid container spacing={3}>
          {data?.map((staff) => (
            <Grid key={staff.name} item xs={12} lg={6}>
              <MKBox mb={1}>
                <StaffCard
                  image={staff.image}
                  name={staff.name}
                  position={{ color: "primary", label: staff.title }}
                  bio={staff.bio}
                  shortBio={staff.shortBio}
                />
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StaffSection;
