// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimpleBackgroundCard from "components/cards/SimpleBackgroundCard";
import { useCallback, useEffect, useState } from "react";

// class to fetch json
import siteData from "services/json-data-api";

function WorshipSection() {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    const json = await siteData.worshipSection();

    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const itemIsLoaded = (item) => {
    if (item !== null || item !== undefined) return true;
    return false;
  };

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          flexDirection="column"
          textAlign="center"
          mx="auto"
          mb={3}
        >
          <MKTypography variant="h3">Worship With Us</MKTypography>
        </Grid>
        <Grid container spacing={3} mt={4}>
          {data?.map((item) => (
            <Grid key={item.title} item xs={12} md={6} lg={4}>
              {itemIsLoaded(item) === true ? (
                <SimpleBackgroundCard
                  color="dark"
                  image={item.image}
                  title={item.title}
                  description={item.description}
                />
              ) : null}
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WorshipSection;
