/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import YouTubeSingleVideoPlayer from "components/YouTubePlayer/YouTubeSingleVideoPlayer";

function AbortionDoc() {
  const video = "VPu59scsBA8";

  return (
    <MKBox>
      <MKTypography variant="h5" textAlign="center" my="30px">
        End Abortion in Alabama -{" "}
        <a
          href="https://www.legislature.state.al.us/pdf/SearchableInstruments/2023RS/HB454-int.pdf"
          target="blank"
        >
          HB454
        </a>
      </MKTypography>

      <MKTypography variant="h5" textAlign="center" my="30px">
        The fight to end abortion in Alabama is not over. Please take the time to watch this
        documentary on the difference in just "Pro Life" and actually abolishing abortion.
      </MKTypography>

      <MKBox mx="auto" my="40px" maxWidth="1440px">
        <YouTubeSingleVideoPlayer video={video} />
      </MKBox>
    </MKBox>
  );
}

export default AbortionDoc;
