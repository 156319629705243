import React from "react";

import MKBox from "components/MKBox";
// import RotatingCard from "components/cards/RotatingCard";
// import RotatingCardFront from "components/cards/RotatingCard/RotatingCardFront";
// import RotatingCardBack from "components/cards/RotatingCard/RotatingCardBack";
import { PFM_URL } from "data/urls";
import { PFM_IMAGE, PFM_LOGO } from "data/image-urls";
import MinistryCard from "pages/Ministries/components/MinistryCard";

export default function PrayForMe() {
  return (
    <MKBox component="section" mt={4} py={4} style={{ display: "flex", justifyContent: "center" }}>
      {/* <RotatingCard>
        <RotatingCardFront image={PFM_IMAGE} icon="touch_app" logo={PFM_LOGO} color="dark" />
        <RotatingCardBack
          image={PFM_IMAGE}
          color="dark"
          description="During the school
                year, members from our church commit to pray for students by name. Through this movement, our church
                seeks to rally around the next generation for the glory of Christ!"
          action={{
            type: "external",
            route: PFM_URL,
            label: "Learn More",
          }}
        />
      </RotatingCard> */}

      <MinistryCard
        logo={PFM_LOGO}
        image={PFM_IMAGE}
        color="dark"
        description="During the school
              year, members from our church commit to pray for students by name. Through this movement, our church
              seeks to rally around the next generation for the glory of Christ!"
        action={{
          type: "external",
          route: PFM_URL,
          label: "Learn More",
        }}
      />
    </MKBox>
  );
}
