import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
// import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Zoom } from "@mui/material";

function StaffModal({ name, bio }) {
  const [show, setShow] = useState(false);
  const toggleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(!show);
  };

  return (
    <>
      <MKTypography component="a" href="" variant="body2" color="text" onClick={toggleModal}>
        {" "}
        more
      </MKTypography>
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Zoom direction="down" in={show} timeout={300}>
          <MKBox
            position="relative"
            maxWidth="500px"
            minWidth="300px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alginitems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">{name}</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKTypography variant="body2" color="text" fontWeight="regular">
                {bio}
              </MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />
          </MKBox>
        </Zoom>
      </Modal>
    </>
  );
}

// Typechecking props for the HorizontalTeamCard
StaffModal.propTypes = {
  name: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
};

export default StaffModal;
