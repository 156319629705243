// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function MinistriesHeader() {
  return (
    <MKBox component="section" py={6} mt={4}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography variant="h3" mb={3}>
            Ministry Partners
          </MKTypography>
          <MKTypography component="p" variant="body2">
            Through the Cooperative Program of the Southern Baptist Convention (SBC), we partner
            with over 47,000 local churches in sending and supporting missionaries who bring the
            good news of Jesus Christ to people around the world. The SBC is also very involved in
            global disaster relief efforts. Additionally, First Baptist Church of Eclectic partners
            with many independent Christian ministries to meet the everyday needs of people in our
            local community and across the globe.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default MinistriesHeader;
