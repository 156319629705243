import { Fragment, useCallback, useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// FBCE components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import BibleModal from "components/Modal/BibleModal";
import DefaultFooter from "components/footers/DefaultFooter";
import DefaultNavbar from "components/Navbars/DefaultNavbar";

// json fetch class
import siteData from "services/json-data-api";

function WhatWeTeachPage() {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    const json = await siteData.baptistFaith();

    setData(json);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const referenceComma = (length, index) => {
    // console.log(length - 1);
    if (index !== length - 1) return <>, </>;
    return null;
  };

  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Baptist Faith & Message
                  </MKTypography>
                  <MKTypography variant="body2" color="secondary">
                    2000
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  {data?.map((item) => {
                    const section = { ...item.section };

                    return (
                      <Fragment key={section.title}>
                        <MKTypography key={section.title} variant="h5" mt={6}>
                          {section.title}
                        </MKTypography>
                        <MKTypography key={section.text} variant="body2" color="text">
                          {section.text}
                        </MKTypography>
                        {section.subtext?.map((subItem) => (
                          <Fragment key={subItem.text}>
                            <MKTypography key={subItem.title} variant="h6" mt={6}>
                              {subItem.title}
                            </MKTypography>
                            <MKTypography key={subItem.text} variant="body2" color="text">
                              {subItem.text}
                              <br />
                            </MKTypography>
                            {subItem.references?.map((reference, index) => (
                              <Fragment key={reference}>
                                <BibleModal reference={reference} />
                                {referenceComma(subItem.references.length, index)}
                              </Fragment>
                            ))}
                          </Fragment>
                        ))}
                        {section.references?.map((reference, index) => (
                          <Fragment key={reference}>
                            <BibleModal reference={reference} />
                            {referenceComma(section.references.length, index)}
                          </Fragment>
                        ))}
                      </Fragment>
                    );
                  })}
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default WhatWeTeachPage;
