// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// FBCE components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// About Us page sections
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/footers/DefaultFooter";

function GivePage() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox width="100%" sx={{ background: "rgb(27, 38, 49)" }}>
        <Container>
          <Grid
            container
            // item
            // xs={12}
            // lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography variant="h2" textAlign="center" color="white" mt="100px">
              Give to FBC Eclectic
            </MKTypography>

            <MKTypography
              variant="body1"
              textAlign="center"
              color="white"
              my="20px"
              //   mx="20px"
            >
              Your gift is safe & secure and goes directly to First Baptist Church Eclectic. You’ll
              be able to create an account to track your gifts, setup recurring giving, and more!
            </MKTypography>
            <MKTypography
              variant="body2"
              textAlign="center"
              color="white"
              my="20px"
              //   mx="20px"
            >
              Give below or download the Tithe.ly Giving App
            </MKTypography>
            <MKBox
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                mb: "20px",
              }}
            >
              <a href="https://bit.ly/TithelyOniOS" target="_blank" rel="noreferrer">
                <MKBox
                  component="img"
                  maxWidth="150px"
                  mx="10px"
                  src="https://assets-global.website-files.com/5f6a31379c9e2712518b8d6c/5f8f15d8420f9a5ca4807099_app-store.webp"
                />
              </a>
              <a href="https://bit.ly/TithelyOnAndroid" target="_blank" rel="noreferrer">
                <MKBox
                  component="img"
                  width="150px"
                  mx="10px"
                  src="https://assets-global.website-files.com/5f6a31379c9e2712518b8d6c/5f8f15d8420f9ac82a80709b_google-play.webp"
                />
              </a>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <div className="tithely-container">
        <iframe
          width="100%"
          height="800"
          src="https://tithe.ly/give_new/www/#/tithely/give"
          frameBorder="0"
          allowpaymentrequest="true"
          title="Give"
        />
      </div>
      <MKBox px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default GivePage;
