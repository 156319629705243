/* eslint-disable */
import { callComponants } from "./youtube-api-call-components";

class YouTubeAPI {
  constructor(
    activeTab,
    pageToken,
    setSelectedVideo,
    setVideos,
    setNextPageToken,
    setPrevPageToken,
    setPerPage,
    setTotalVideos
  ) {
    this.activeTab = activeTab;
    this.pageToken = pageToken;
    this.setSelectedVideo = setSelectedVideo;
    this.setVideos = setVideos;
    this.setNextPageToken = setNextPageToken;
    this.setPrevPageToken = setPrevPageToken;
    this.setPerPage = setPerPage;
    this.setTotalVideos = setTotalVideos;
  }

  async fetch() {
    const url = await this.setURL();
    const headers = {
      headers: {
        Accept: "application/json",
      },
    };

    const response = await fetch(url, headers);

    if (response.status === 200) {
      const data = await response.json();
      await this.setData(data);
    }
  }

  deletesFiltered(video) {
    return video.snippet.title !== "Deleted video";
  }

  privatesFiltered(video) {
    return video.snippet.title !== "Private video";
  }

  async setData(data) {
    const videosFiltered = data.items?.filter(this.deletesFiltered).filter(this.privatesFiltered);

    this.setNextPageToken(data.nextPageToken);
    this.setPrevPageToken(data.prevPageToken);
    this.setPerPage(data.pageInfo.resultsPerPage);
    this.setTotalVideos(data.pageInfo.totalResults);
    this.setVideos(videosFiltered);
    this.setSelectedVideo(videosFiltered[0]);
  }

  async setURL() {
    const apiKey = callComponants.key;
    const baseURL = callComponants.baseURL;
    const parts = callComponants.parts;
    const maxResults = callComponants.maxResults;
    const playlistID = callComponants.playlistID;
    const fields = callComponants.fields.videoList;

    const urlPrefix = `${baseURL}?part=${parts}&maxResults=${maxResults}`;
    const urlSuffix = `&fields=${fields}&key=${apiKey}`;

    if (this.activeTab === this.videoFilters.worshipServices) {
      if (this.pageToken === undefined || this.pageToken === null) {
        return `${urlPrefix}&playlistId=${playlistID.worshipServices}${urlSuffix}`;
      }
      return `${urlPrefix}&pageToken=${this.pageToken}&playlistId=${playlistID.worshipServices}${urlSuffix}`;
    }

    if (this.activeTab === this.videoFilters.studentWorship) {
      if (this.pageToken === undefined || this.pageToken === null) {
        return `${urlPrefix}&playlistId=${playlistID.studentWorship}${urlSuffix}`;
      }
      return `${urlPrefix}&pageToken=${this.pageToken}&playlistId=${playlistID.studentWorship}${urlSuffix}`;
    }

    if (this.activeTab === this.videoFilters.other) {
      if (this.pageToken === undefined || this.pageToken === null) {
        return `${urlPrefix}&playlistId=${playlistID.other}${urlSuffix}`;
      }
      return `${urlPrefix}&pageToken=${this.pageToken}&playlistId=${playlistID.other}${urlSuffix}`;
    }
  }

  videoFilters = {
    worshipServices: "Worship Services",
    studentWorship: "Student Worship",
    other: "Other Videos",
  };
}

export default YouTubeAPI;
