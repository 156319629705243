/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import YouTubeLivePlayer from "components/YouTubePlayer/YouTubeLivePlayer";
import MKTypography from "components/MKTypography";
import LiveStreamAPI from "services/live-stream-api";
import MKBox from "components/MKBox";

function Livestream() {
  const [isLive, setIsLive] = useState(false);
  const [video, setVideo] = useState();
  const liveStreamAPI = new LiveStreamAPI(setIsLive, setVideo);

  useEffect(() => {
    liveStreamAPI.fetch();
    console.log(isLive);
    console.log(video);
  }, []);

  return isLive ? (
    <MKBox>
      <MKTypography variant="h3" textAlign="center" my="30px">
        Join Us Live
      </MKTypography>
      <MKBox mx="auto" my="40px" maxWidth="1440px">
        <YouTubeLivePlayer video={video} />
      </MKBox>
    </MKBox>
  ) : (
    <div />
  );
}

Livestream.propTypes = {
  offlineComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType, PropTypes.func]),
};

Livestream.defaultProps = {
  offlineComponent: null,
};

export default Livestream;
