import React from "react";

import MKBox from "components/MKBox";
// import RotatingCard from "components/cards/RotatingCard";
// import RotatingCardFront from "components/cards/RotatingCard/RotatingCardFront";
// import RotatingCardBack from "components/cards/RotatingCard/RotatingCardBack";
import { UPWARD_LOGO, UPWARD_IMAGE } from "data/image-urls";
import { UPWARD_URL } from "data/urls";
import MinistryCard from "pages/Ministries/components/MinistryCard";

export default function Upward() {
  return (
    <MKBox component="section" mt={4} py={4} style={{ display: "flex", justifyContent: "center" }}>
      {/* <RotatingCard>
        <RotatingCardFront image={UPWARD_IMAGE} icon="touch_app" logo={UPWARD_LOGO} color="dark" />
        <RotatingCardBack
          image={UPWARD_IMAGE}
          color="dark"
          description="Upward exists to promote the discovery of Jesus Christ through sports. Upward Basketball is open to all children (boys and girls), grades 1st - 6th. Registration for the 2022 season has ended."
          action={{
            type: "external",
            route: UPWARD_URL,
            label: "Learn More",
          }}
        />
      </RotatingCard> */}
      <MinistryCard
        image={UPWARD_IMAGE}
        logo={UPWARD_LOGO}
        // color="dark"
        description="Upward exists to promote the discovery of Jesus Christ through sports. Upward Basketball is open to all children (boys and girls), grades 1st - 6th. Registration for the 2022 season has ended."
        action={{
          type: "external",
          route: UPWARD_URL,
          label: "Learn More",
        }}
      />
    </MKBox>
  );
}
