/* eslint-disable import/prefer-default-export */
// import { formatPhoneNumber } from "../utils/utils";
import { doc, setDoc } from "firebase/firestore";
import { db } from "./contactFirebase";

// const serviceID = 'service_3fgcwz9';
// const templateID = 'template_5dg1ys6';
// const userID = 'user_3ub5f4KJJHBND1Wzl1FQi';

// Sends email when equipment is updated:
const sendContactFormEmail = async (contactData) => {
  // Creates the paramaters for the email template
  const recipient = "wbrittgreen@gmail.com";
  // const recipients = "thewaymediaco@gmail.com";
  const subject = `FBCEclectic.com Contact Form Submission`;
  const body = `<body>
                    <section>
                        <p>${contactData.timestamp}</p>
                        <p><strong>Name:</strong> ${contactData.name}</p>
                        <p><strong>Email:</strong> ${contactData.email}</p>
                        <p><strong>Message:</strong> ${contactData.message}</p>
                    </section>
                    <body>`;

  // Sets paramaters for the email template
  const emailData = {
    to: recipient,
    from: "FBCEclectic.com Contact Form<psides.solutions@outlook.com>",
    replyTo: contactData.email,
    message: {
      subject,
      html: body,
    },
  };

  // Sends the email
  await setDoc(doc(db, "email", contactData.id), emailData);
};

export { sendContactFormEmail };
