import React from "react";

import MKBox from "components/MKBox";
// import RotatingCard from "components/cards/RotatingCard";
// import RotatingCardFront from "components/cards/RotatingCard/RotatingCardFront";
// import RotatingCardBack from "components/cards/RotatingCard/RotatingCardBack";
import { KINDNESS_IMAGE } from "data/image-urls";
import MinistryCard from "pages/Ministries/components/MinistryCard";

export default function KindnessMinistry() {
  return (
    <MKBox component="section" mt={4} py={4} style={{ display: "flex", justifyContent: "center" }}>
      {/* <RotatingCard>
        <RotatingCardFront
          image={KINDNESS_IMAGE}
          icon="touch_app"
          color="dark"
          title="Eclectic Kindness Ministry"
        />
        <RotatingCardBack
          image={KINDNESS_IMAGE}
          color="dark"
          description="Each week, families in our community benefit from our local food pantry. The Kindness
          ministry serves all who qualify for assistance and is open on the 1st and 3rd Thursday
          of each month from 9am to Noon. In addition to food, we stock clothing, toiletries, and
          other household items. We also offer friendly conversation and prayer for all of our
          guests."
          action={{
            type: "external",
            route: "tel: +13345414444",
            label: "Call for Details",
          }}
        />
      </RotatingCard> */}
      <MinistryCard
        image={KINDNESS_IMAGE}
        title="Eclectic Kindness Ministry"
        color="dark"
        description="Each week, families in our community benefit from our local food pantry. The Kindness
        ministry serves all who qualify for assistance and is open on the 1st and 3rd Thursday
        of each month from 9am to Noon. In addition to food, we stock clothing, toiletries, and
        other household items. We also offer friendly conversation and prayer for all of our
        guests."
        action={{
          type: "external",
          route: "tel: +13345414444",
          label: "Call for Details",
        }}
      />
    </MKBox>
  );
}
