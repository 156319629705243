/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import "pages/Media/Video/styles/video-section.css";
import YouTubePlayer from "components/YouTubePlayer/YouTubePlayer";
import VideoGrid from "pages/Media/Video/components/VideoGrid";
import FilterTabs from "components/FilterTabs";
import YouTubeAPI from "services/youtube-api";

const VideoFilters = {
  worshipServices: "Worship Services",
  studentWorship: "Student Worship",
  other: "Other Videos",
};

function VideosSection() {
  const ref = useRef(null);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [videos, setVideos] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();
  const [prevPageToken, setPrevPageToken] = useState();
  const [pageToken, setPageToken] = useState();
  const [perPage, setPerPage] = useState(20);
  const [totalVideos, setTotalVideos] = useState(0);
  const [page, setPage] = useState(0);
  const tabOptions = [
    VideoFilters.worshipServices,
    VideoFilters.studentWorship,
    VideoFilters.other,
  ];
  const [activeTab, setActiveTab] = useState(tabOptions[0]);

  const youtubeAPI = new YouTubeAPI(
    activeTab,
    pageToken,
    setSelectedVideo,
    setVideos,
    setNextPageToken,
    setPrevPageToken,
    setPerPage,
    setTotalVideos
  );

  const fetchVideos = useCallback(async () => {
    await youtubeAPI.fetch();
  }, [page, activeTab]);

  useEffect(() => {
    fetchVideos();
    console.log(videos);
  }, [page, activeTab]);

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setPageToken(nextPageToken);
      setPage(newPage);
    } else if (newPage < page) {
      setPageToken(prevPageToken);
      setPage(newPage);
    } else {
      return;
    }
  };

  function selectVideo(e, video) {
    e.preventDefault();
    e.stopPropagation();

    setSelectedVideo(video);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <MKBox style={{ background: "white" }} shadow="lg">
      <FilterTabs
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setPageToken={setPageToken}
        setPage={setPage}
      />
      <MKBox ref={ref} mx="auto" my="40px" maxWidth="1440px">
        <YouTubePlayer video={selectedVideo} />
      </MKBox>
      <VideoGrid
        videos={videos}
        selectedVideo={selectedVideo}
        selectVideo={selectVideo}
        totalVideos={totalVideos}
        page={page}
        handleChangePage={handleChangePage}
        perPage={perPage}
      />
    </MKBox>
  );
}

export default VideosSection;
